<template>
  <sheet-drawer v-model="drawer" @close="cancel">
    <v-form class="fill-height" @submit.prevent v-if="obModel && drawer">
      <gw-form-observer
        @save="save"
        @cancel="cancel"
        :loading="isLoading"
        :dirty="isDirty"
      >
        <v-tabs right v-model="tab">
          <v-tab v-for="(item, index) in tabItems" :key="`tab-item-${index}`">
            {{ $t(slugify(item)) }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(item, index) in tabItems"
            :key="`tab-content-${index}`"
          >
            <component :is="item" v-model="obModel" />
          </v-tab-item>
        </v-tabs-items>
      </gw-form-observer>
    </v-form>
  </sheet-drawer>
</template>

<script lang="ts">
import { Mixins, Component, PropSync } from "vue-property-decorator";
import CategoriesMixin from "@/modules/categories/mixins/CategoriesMixin";
import SheetDrawer from "@/layout/components/main/SheetDrawer.vue";
import FormTabSettings from "@/modules/categories/components/tabs/Settings.vue";
import FormTabImages from "@/modules/categories/components/tabs/Images.vue";

@Component({
  components: {
    SheetDrawer,
    FormTabSettings,
    FormTabImages,
  },
})
export default class CategoriesForm extends Mixins(CategoriesMixin) {
  @PropSync("open", { type: Boolean, default: false }) drawer!: boolean;
  tab = 0;
  tabItems: string[] = ["form-tab-settings", "form-tab-images"];
}
</script>
